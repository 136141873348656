<template>
    <div class="section">
        <page-title :title="$t('client.pages.myAccount.title')" class="my-3"></page-title>
        <b-row class="my-3">
            <b-col cols="12" lg="12">
                <b-card :title="$t('client.pages.myAccount.form.resetPassword.title')" title-tag="h5" class="mb-3">
                    <div class="mb-3 col-sm-3">
                        <change-password></change-password>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import ChangePassword from '@/components/auth/ChangePassword';

export default {
    name: 'ClientUserMyAccount',
    components: {
        PageTitle,
        ChangePassword
    }
};
</script>
