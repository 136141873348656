<template>
    <Validation-observer class="my-3" ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
            <Validation-provider
                :name="$t('client.pages.myAccount.form.resetPassword.placeholders.oldPassword')"
                :rules="{ required: true }"
                v-slot="validationContext"
            >
                <b-form-group>
                    <b-form-input
                        id="password"
                        type="password"
                        v-model="form.old_password"
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('client.pages.myAccount.form.resetPassword.placeholders.oldPassword')"
                    ></b-form-input>

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
            </Validation-provider>

            <Validation-provider
                :name="$t('client.pages.myAccount.form.resetPassword.placeholders.newPassword')"
                :rules="{ required: true, min: 8, regex: regex.PASSWORD }"
                v-slot="validationContext"
            >
                <b-form-group>
                    <b-form-input
                        id="password"
                        type="password"
                        vid="password"
                        v-model="form.new_password"
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('client.pages.myAccount.form.resetPassword.placeholders.newPassword')"
                    ></b-form-input>

                    <b-form-invalid-feedback v-if="validationContext">
                        {{ passwordErrorMessage(validationContext) }}
                    </b-form-invalid-feedback>
                </b-form-group>
            </Validation-provider>

            <Validation-provider
                :name="$t('client.pages.myAccount.form.resetPassword.placeholders.confirmPassword')"
                rules="required|confirmed:password"
                v-slot="validationContext"
            >
                <b-form-group>
                    <b-form-input
                        id="confirmPassword"
                        type="password"
                        v-model="form.password"
                        :state="getValidationState(validationContext)"
                        :placeholder="$t('client.pages.myAccount.form.resetPassword.placeholders.confirmPassword')"
                    ></b-form-input>

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
            </Validation-provider>

            <b-button type="submit" variant="danger" block>{{ $t('auth.login.buttons.send') }}</b-button>
        </b-form>
    </Validation-observer>
</template>

<script>
import { REGEX } from '@/constants';
import { AuthRepository } from '@/repositories';
import { AUTH_REFRESH_TOKEN } from '@/store/actions/auth';

export default {
    name: 'ChangePassword',
    data() {
        return {
            form: {
                old_password: null,
                new_password: null
            },
            requestSuccess: true,
            regex: REGEX
        };
    },
    methods: {
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit() {
            const { old_password, new_password } = this.form;
            AuthRepository.changePassword(old_password, new_password)
                .then(() => {
                    if (this.$store.getters['auth/isClientUser']) {
                        this.$store.dispatch(`auth/${AUTH_REFRESH_TOKEN}`).then(() => {
                            this.$router.push({ name: 'ClientHome' });
                        });
                    }
                    this.$toast.success(this.$i18n.t('auth.resetPassword.notification.success'));
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('auth.resetPassword.notification.error'));
                });
        },
        passwordErrorMessage({ failedRules }) {
            if ('regex' in failedRules) {
                return this.$t('auth.login.form.email.errors.regex');
            }
            return failedRules[Object.keys(failedRules)[0]];
        }
    }
};
</script>
